.gallery-container {
    width: 400px;
    height: 400px;
    margin: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: opacity 500ms ease-in-out forwards;

    @media screen and (max-width: 1000px) {
        margin: 0 0 15px 0px!important;
    }

    .gallery {
        width: 400px;
        height: 400px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
    
        cursor: pointer;
        
        transition: all 250ms;
        &:hover {
            opacity: 0.90;
        }
    }
    p {
        text-transform: uppercase;
        font-size: 2.8em;
        font-family: 'Muli';
        position: absolute;
        padding: 0;
        margin: 0;
        color: white;

        pointer-events: none;
    }
}
