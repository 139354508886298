@import url(https://fonts.googleapis.com/css?family=Muli);
.menu {
  z-index: 2;
  font-family: "Muli";
  font-size: 18px;
  position: fixed;
  left: 35px;
  top: 35px;
  font-weight: 300; }
  .menu .hamburger {
    position: absolute;
    z-index: 10;
    left: 0;
    padding: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .menu .hamburger * {
      transition-delay: 0s; }
    @media screen and (min-width: 1001px) {
      .menu .hamburger {
        display: none; } }
  .menu .hamburger:focus {
    outline: none !important; }
  .menu a {
    color: #0B132B;
    line-height: 35px;
    text-decoration: none;
    transition: opacity 0.2s; }
    .menu a.active {
      font-weight: 400;
      opacity: 1 !important;
      pointer-events: none; }
    .menu a:hover {
      opacity: 0.68; }
  .menu ul {
    list-style: none;
    padding-left: 0;
    margin: 15px 0; }
    .menu ul li {
      margin: 0; }
    @media screen and (min-width: 1001px) {
      .menu ul {
        opacity: 1 !important;
        -webkit-animation: none !important;
                animation: none !important; } }
  .menu a.full-text {
    display: none; }
  .menu a.wrapped-text {
    display: block; }
  @media screen and (max-width: 1000px) {
    .menu {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 90%;
      height: 100px;
      align-items: center;
      justify-content: center; }
      .menu ul {
        display: flex;
        top: -9999px;
        position: absolute;
        opacity: 0;
        transition: opacity 350ms ease-in; }
      .menu ul.active {
        display: flex;
        opacity: 1;
        top: inherit;
        position: fixed;
        background: rgba(255, 255, 255, 0.8);
        left: 0;
        height: 100%;
        width: 100%;
        bottom: 0;
        padding: 0;
        margin: 0;
        font-size: 2em;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .menu ul.active a {
          opacity: 1; }
      .menu .logo {
        text-align: center; }
        .menu .logo a.full-text {
          display: block; }
        .menu .logo a.wrapped-text {
          display: none; } }

.logo {
  font-size: 46px;
  font-family: 'Unisono';
  text-align: left;
  color: black;
  z-index: 1; }
  @media screen and (max-width: 1000px) {
    .logo {
      font-size: 10vw; } }
  .logo a {
    padding: 0;
    margin: 0;
    line-height: 50px;
    opacity: 1 !important; }

.photo {
  display: flex;
  align-items: center;
  opacity: 0;
  justify-content: center; }
  .photo.has-gallery {
    cursor: pointer; }
    .photo.has-gallery:hover p {
      opacity: 0.7; }
  .photo p {
    position: absolute;
    font-size: 2vw;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    width: 90%;
    color: white;
    transition: opacity 0.5s;
    text-align: center;
    opacity: 0; }
    @media screen and (max-width: 1000px) {
      .photo p {
        font-size: 6vw; } }
  .photo.first-row {
    -webkit-animation: opacity 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: opacity 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.slide-top {
  -webkit-animation-name: slide-top;
          animation-name: slide-top;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.rows-container {
  display: flex;
  top: 100px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto; }
  .rows-container .home-row {
    display: flex;
    justify-content: center;
    height: 95vh;
    align-items: center;
    position: relative; }
    @media screen and (min-width: 1400px) {
      .rows-container .home-row {
        margin-bottom: 15px; } }
    @media screen and (min-width: 1000px) {
      .rows-container .home-row.home-row-3 {
        justify-content: space-evenly; }
        .rows-container .home-row.home-row-3 .photo {
          width: 33%; }
          .rows-container .home-row.home-row-3 .photo.small img {
            -webkit-transform: scale(0.5) !important;
                    transform: scale(0.5) !important; }
          .rows-container .home-row.home-row-3 .photo.medium img {
            -webkit-transform: scale(1) !important;
                    transform: scale(1) !important; }
          .rows-container .home-row.home-row-3 .photo.large img {
            -webkit-transform: scale(0.8) !important;
                    transform: scale(0.8) !important; }
        .rows-container .home-row.home-row-3 img {
          -webkit-transform: scale(0.5) !important;
                  transform: scale(0.5) !important; } }
    .rows-container .home-row .photo {
      height: 100%; }
      @media screen and (min-width: 1000px) {
        .rows-container .home-row .photo.small img {
          -webkit-transform: scale(0.75);
                  transform: scale(0.75); }
        .rows-container .home-row .photo.medium img {
          -webkit-transform: scale(1);
                  transform: scale(1); }
        .rows-container .home-row .photo.large img {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); } }
      .rows-container .home-row .photo img {
        height: 100%; }
    @media screen and (max-width: 1555px) {
      .rows-container .home-row .photo img {
        height: 90%; } }
    @media screen and (max-width: 1400px) {
      .rows-container .home-row .photo img {
        height: 80%; } }
    @media screen and (max-width: 1300px) {
      .rows-container .home-row .photo img {
        height: 70%; } }
    @media screen and (max-width: 1200px) {
      .rows-container .home-row .photo img {
        height: 60%; } }
    .rows-container .home-row.first {
      padding-top: 50px; }
      @media screen and (max-width: 1000px) {
        .rows-container .home-row.first {
          padding-top: 0 !important; } }
  @media screen and (max-width: 1000px) {
    .rows-container {
      width: 95%;
      margin: 0 auto;
      position: relative; }
      .rows-container .home-row {
        display: block !important;
        height: unset !important;
        padding-bottom: 0px !important; }
      .rows-container .photo,
      .rows-container img {
        width: 100% !important;
        height: auto !important;
        -webkit-animation: none;
                animation: none;
        opacity: 1; }
      .rows-container .photo {
        margin: 0;
        margin-bottom: 20px; } }

.gallery-container {
  width: 400px;
  height: 400px;
  margin: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  -webkit-animation: opacity 500ms ease-in-out forwards;
          animation: opacity 500ms ease-in-out forwards; }
  @media screen and (max-width: 1000px) {
    .gallery-container {
      margin: 0 0 15px 0px !important; } }
  .gallery-container .gallery {
    width: 400px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    cursor: pointer;
    transition: all 250ms; }
    .gallery-container .gallery:hover {
      opacity: 0.90; }
  .gallery-container p {
    text-transform: uppercase;
    font-size: 2.8em;
    font-family: 'Muli';
    position: absolute;
    padding: 0;
    margin: 0;
    color: white;
    pointer-events: none; }

.galleries {
  position: absolute;
  top: 50px;
  left: 55px;
  height: auto !important;
  padding-top: 0 !important; }
  @media screen and (min-width: 1001px) {
    .galleries {
      width: 90% !important;
      margin-left: auto !important;
      right: 0; } }
  @media screen and (max-width: 1001px) {
    .galleries {
      left: 0;
      top: 80px; }
      .galleries .galleries-container {
        justify-content: center !important; } }
  .galleries .g-div {
    display: flex;
    justify-content: center; }
    .galleries .g-div .galleries-container {
      display: flex;
      justify-content: left;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-left: auto;
      -webkit-animation: opacity 1s slide-in;
              animation: opacity 1s slide-in;
      width: calc(100% - 200px); }
      @media screen and (max-width: 1000px) {
        .galleries .g-div .galleries-container {
          margin-top: 15px;
          width: 100%;
          flex-direction: column;
          margin-left: unset; } }
      @media screen and (max-width: 550px) {
        .galleries .g-div .galleries-container {
          width: 95% !important; }
          .galleries .g-div .galleries-container .gallery {
            width: 100%; }
          .galleries .g-div .galleries-container .gallery-container {
            width: 100%;
            height: 350px; } }

.page.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh; }
  .page.contact h1 {
    opacity: 0.75; }
  .page.contact a {
    text-decoration: none;
    color: black;
    display: block;
    margin-bottom: 15px; }
  .page.contact .icons {
    margin-top: 15px; }
    .page.contact .icons a {
      margin: 8px;
      display: inline-block; }
      .page.contact .icons a img {
        width: 28px;
        -webkit-filter: grayscale(1);
                filter: grayscale(1); }

.page.about {
  display: flex;
  justify-content: center;
  align-items: center; }
  .page.about .about-container {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 35px; }
    .page.about .about-container img {
      width: 400px;
      margin-bottom: 30px; }
    .page.about .about-container p {
      text-align: justify;
      white-space: pre-line;
      width: 400px;
      margin-top: 0;
      padding: 0;
      text-align-last: left; }
    @media screen and (max-width: 1000px) {
      .page.about .about-container {
        margin-top: 100px; }
        .page.about .about-container img, .page.about .about-container p {
          width: 50% !important; } }
    @media screen and (max-width: 850px) {
      .page.about .about-container {
        margin-top: 100px; }
        .page.about .about-container img, .page.about .about-container p {
          width: 100% !important; } }

.page.gallery {
  top: 35px;
  position: absolute; }
  @media screen and (min-width: 1000px) {
    .page.gallery {
      width: calc(100% - 200px); } }
  @media screen and (max-width: 1000px) {
    .page.gallery {
      top: 80px; } }
  .page.gallery .gallery-photos-container {
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 500px;
    margin: 0 auto;
    -webkit-animation: opacity 750ms ease-out;
            animation: opacity 750ms ease-out; }
    @media screen and (max-width: 1000px) {
      .page.gallery .gallery-photos-container {
        width: 95%; } }
    .page.gallery .gallery-photos-container img {
      width: 100%;
      height: auto;
      margin-top: 15px; }

.page.work {
  top: 35px;
  position: absolute;
  width: calc(100% - 200px); }
  @media screen and (max-width: 1000px) {
    .page.work {
      top: 80px;
      width: 100%; } }
  .page.work .work-photos-container {
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 500px;
    margin: 0 auto;
    -webkit-animation: opacity 750ms ease-out;
            animation: opacity 750ms ease-out; }
    @media screen and (max-width: 1000px) {
      .page.work .work-photos-container {
        width: 95%; } }
    .page.work .work-photos-container img {
      width: 100%;
      height: auto;
      margin-top: 15px; }

@-webkit-keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-out {
  -webkit-animation: fade-out 350ms ease-out both;
  animation: fade-out 350ms ease-out both; }

/* ----------------------------------------------
 * Generated by Animista on 2018-10-30 13:25:16
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fade-in {
  -webkit-animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

/* ----------------------------------------------
 * Generated by Animista on 2018-12-23 18:22:24
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: black; }

.hamburger-box {
  width: 26px;
  height: 15px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 26px;
    height: 1px;
    background-color: black;
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -7px; }
  .hamburger-inner::after {
    bottom: -7px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  -webkit-perspective: 52px;
          perspective: 52px; }

.hamburger--3dx .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 52px;
          perspective: 52px; }

.hamburger--3dx-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  -webkit-perspective: 52px;
          perspective: 52px; }

.hamburger--3dy .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 52px;
          perspective: 52px; }

.hamburger--3dy-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 52px;
          perspective: 52px; }

.hamburger--3dxy .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
          transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 52px;
          perspective: 52px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
          transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-5.2px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-5.2px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-5.2px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-5.2px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(5.2px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(5.2px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(5.2px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(5.2px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(-5.2px, -6.5px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-5.2px, -6.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(-5.2px, 6.5px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-5.2px, 6.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(5.2px, -6.5px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(5.2px, -6.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(5.2px, 6.5px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(5.2px, 6.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -14px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
          transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -14px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -7px, 0) rotate(45deg);
          transform: translate3d(0, -7px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 0.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 7px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 14px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
          transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(-270deg);
            transform: translate3d(0, -14px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 0.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 7px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 14px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(-135deg);
          transform: translate3d(0, 7px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(270deg);
            transform: translate3d(0, -14px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic .hamburger-inner::after {
      top: 7px;
      right: 0;
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -52px;
      top: -52px;
      -webkit-transform: translate3d(52px, 52px, 0) rotate(45deg);
              transform: translate3d(52px, 52px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -52px;
      top: -52px;
      -webkit-transform: translate3d(-52px, 52px, 0) rotate(-45deg);
              transform: translate3d(-52px, 52px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 7px;
      right: 0;
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -52px;
      top: 52px;
      -webkit-transform: translate3d(52px, -52px, 0) rotate(-45deg);
              transform: translate3d(52px, -52px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -52px;
      top: 52px;
      -webkit-transform: translate3d(-52px, -52px, 0) rotate(45deg);
              transform: translate3d(-52px, -52px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 0.5px; }
  .hamburger--slider .hamburger-inner::before {
    top: 7px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 14px; }

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
          transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-3.71429px, -6px, 0);
            transform: rotate(-45deg) translate3d(-3.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(-90deg);
            transform: translate3d(0, -14px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 0.5px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 7px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 14px; }

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(-45deg);
          transform: translate3d(0, 7px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    -webkit-transform: rotate(45deg) translate3d(3.71429px, -6px, 0);
            transform: rotate(45deg) translate3d(3.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(90deg);
            transform: translate3d(0, -14px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 0.5px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 7px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 14px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 7px, 0) rotate(-45deg);
            transform: translate3d(0, 7px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -14px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
          transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: transparent !important;
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-color: transparent !important;
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transform: rotate(765deg);
          transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-765deg);
          transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

html,
body,
#root,
.app,
.page {
  min-height: 90%;
  padding: 0;
  margin: 0;
  color: black; }

@media screen and (min-width: 1000px) {
  .app {
    padding-left: 200px; } }

@font-face {
  font-family: 'Unisono';
  src: url("/static/media/Unisono INK.506eeba5.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: 'Muli'; }
  body > div {
    height: 100%;
    width: 100%; }

.page {
  width: 100%;
  height: 100%; }

