.rows-container {
    display: flex;
    top: 100px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    .home-row {
        display: flex;
        justify-content: center;
        height: 95vh;
        align-items: center;
        position: relative;

        @media screen and (min-width: 1400px) {
            margin-bottom: 15px;
        }


        @media screen and (min-width: 1000px) {
            &.home-row-3 {
                justify-content: space-evenly;

                .photo {
                    width: 33%;

                    &.small {
                        img {
                            transform: scale(0.5) !important;
                        }
                    }

                    &.medium {
                        img {
                            transform: scale(1) !important;
                        }
                    }

                    &.large {
                        img {
                            transform: scale(0.8) !important;
                        }
                    }
                }


                img {
                    transform: scale(0.5) !important;
                }
            }

        }

        .photo {
            height: 100%;

            @media screen and (min-width: 1000px) {
                &.small {
                    img {
                        transform: scale(0.75);
                    }
                }

                &.medium {
                    img {
                        transform: scale(1);
                    }
                }

                &.large {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            img {
                height: 100%;
            }

        }

        @media screen and (max-width: 1555px) {
            .photo img {
                height: 90%;
            }
        }

        @media screen and (max-width: 1400px) {
            .photo img {
                height: 80%;
            }
        }

        @media screen and (max-width: 1300px) {
            .photo img {
                height: 70%;
            }
        }

        @media screen and (max-width: 1200px) {
            .photo img {
                height: 60%;
            }
        }

        // &.home-row-3 {
        //     .photo {
        //         &.large {
        //             width: 150%;
        //             height: 150%;
        //         }

        //         &.medium {
        //             width: 100%;
        //             height: 100%;
        //         }

        //         &.small {
        //             width: 80%;
        //             height: 80%;
        //         }
        //     }
        // }

        // &.home-row-2 {
        //     .photo {
        //         &.large {
        //             width: 60%;
        //             height: 80%;
        //         }

        //         &.medium {
        //             width: 50%;
        //             height: 50%;
        //         }

        //         &.small {
        //             width: 30%;
        //             height: 30%;
        //         }
        //     }
        // }

        // &.home-row-1 {
        //     .photo {
        //         &.large {
        //             width: 100%;
        //             height: 100%;
        //         }

        //         &.medium {
        //             width: 60%;
        //             height: 60%;
        //         }

        //         &.small {
        //             width: 50%;
        //             height: 50%;
        //         }
        //     }
        // }
        // @media screen and (min-width: 1000px) {
        //     .photo {
        //         &.vertical {
        //             width: auto !important;

        //             img {
        //                 width: auto;
        //             }
        //         }

        //         &.horizontal {
        //             height: auto !important;

        //             img {
        //                 height: auto;
        //             }
        //         }
        //     }
        // }

        &.first {
            // height: 95vh;
            // padding-bottom: 100px;
            padding-top: 50px;

            @media screen and (max-width: 1000px) {
                padding-top: 0 !important;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        width: 95%;
        margin: 0 auto;
        position: relative;

        .home-row {
            display: block !important;
            height: unset !important;
            padding-bottom: 0px !important;
        }

        .photo,
        img {
            width: 100% !important;
            height: auto !important;
            animation: none;
            opacity: 1;
        }

        .photo {
            margin: 0;
            margin-bottom: 20px;
        }
    }
}