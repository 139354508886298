.page.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    
    h1 {
        opacity: 0.75;
    }

    a {
        text-decoration: none;
        color: black;
        display: block;
        margin-bottom: 15px;
    }

    .icons {
        margin-top: 15px;
        a {
            margin: 8px;
            display: inline-block;
            img {
                width: 28px;
                filter: grayscale(1);
            }
        }
    }
}