@keyframes opacity {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  -webkit-animation: fade-out 350ms ease-out both;
  animation: fade-out 350ms ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-10-30 13:25:16
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.fade-in {
  -webkit-animation: fade-in .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-12-23 18:22:24
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}