.page.work {
    top: 35px;
    position: absolute;
    width: calc(100% - 200px);
    
    @media screen and (max-width: 1000px) {
        top: 80px;
        width: 100%;
    }
    
    .work-photos-container {
        display: flex;
        padding-bottom: 15px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        width: 500px;
        margin: 0 auto;
        animation: opacity 750ms ease-out;

        @media screen and(max-width: 1000px) {
            width: 95%;
        }

        img {
            width: 100%;
            height: auto;
            margin-top: 15px;
        }

    }
}