@import url('https://fonts.googleapis.com/css?family=Muli');

@import "animations";

$hamburger-layer-height: 1px;
$hamburger-layer-width: 26px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: black;

@import "hamburgers/hamburgers.scss";

html,
body,
#root,
.app,
.page {
    min-height: 90%;
    padding: 0;
    margin: 0;
    color: black;
}

.app {
    @media screen and (min-width: 1000px) {
        padding-left: 200px;
    }
}

@font-face {
    font-family: 'Unisono';
    src: url('../assets/fonts/Unisono INK.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Muli';
    >div {
        height: 100%;
        width: 100%;
    }
}

.page {
    width: 100%;
    height: 100%;
}