.galleries {
    position: absolute;
    top: 50px;
    left: 55px;
    height: auto!important;
    padding-top: 0!important;
    
    @media screen and (min-width: 1001px) {
        width: 90%!important;
        margin-left: auto!important;
        right: 0;
    }

    @media screen and(max-width: 1001px) {
        // position: relative;
        left: 0;
        top: 80px;
        .galleries-container {
            justify-content: center!important;
        }
    }
    .g-div {
        display: flex;
        justify-content: center;
        .galleries-container {
            display: flex;
            justify-content: left;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            margin-left: auto;
            animation: opacity 1s slide-in;
            
            
            width: calc(100% - 200px);

            @media screen and (max-width: 1000px) {
                margin-top: 15px;
                width: 100%;
                flex-direction: column;
                margin-left: unset;
            }

            @media screen and (max-width: 550px) {
                width: 95%!important;
                .gallery {
                    width: 100%;
                }
                .gallery-container {
                    width: 100%;
                    height: 350px;
                }
            }
        }
    }
}
