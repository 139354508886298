.page.about {
    display: flex;
    justify-content: center;
    align-items: center;

    .about-container {
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 35px;   
    
        img {
            width: 400px;
            margin-bottom: 30px;
        }

        p {
            text-align: justify;
            white-space: pre-line;
            width: 400px;
            margin-top: 0;
            padding: 0;

            text-align-last: left;
        }

        @media screen and(max-width: 1000px) {
            margin-top: 100px;
            img, p {
                width: 50%!important
            }
        }

        @media screen and (max-width: 850px) {
            margin-top: 100px;
            img, p {
                width: 100%!important;
            }
        }
    }
}