.logo {
    font-size: 46px;
    font-family: 'Unisono';
    text-align: left;
    color: black;
    z-index: 1;
    @media screen and (max-width: 1000px) {
        font-size: 10vw;
    }

    a {
        padding: 0;
        margin: 0;
        line-height: 50px;
        opacity: 1!important;
    }
}   