.photo {
    // margin: 30px;
    display: flex;
    align-items: center;
    opacity: 0;
    justify-content: center;
    

    &.has-gallery {
        cursor: pointer;
        &:hover {
            p {
                opacity: 0.7;
            }
        }
    }

    p {
        position: absolute;
        font-size: 2vw;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        width: 90%;
        color: white;
        transition: opacity 0.5s;
        text-align: center;
        opacity: 0;

        @media screen and(max-width: 1000px) {
            font-size: 6vw;
        }
    }

    &.first-row {
        -webkit-animation: opacity 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: opacity 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.slide-top {
    animation-name: slide-top;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
    animation-fill-mode: both;
}