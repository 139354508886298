.menu {
    z-index: 2;
    font-family: "Muli";
    font-size: 18px;
    position: fixed;
    left: 35px;
    top: 35px;
    font-weight: 300;

    .hamburger {
        position: absolute;
        z-index: 10;
        left: 0;
        padding: 0;
        user-select: none;

        * {
            transition-delay: 0s;
        }
        @media screen and (min-width: 1001px) {
            display: none;
        }
    }

    .hamburger:focus {
        outline: none!important;
    }

    a {
        color: #0B132B;
        line-height: 35px;
        text-decoration: none;
        &.active {
            font-weight: 400;
            opacity: 1!important;
            pointer-events: none;
        }
        transition: opacity 0.2s;
        &:hover {
            opacity: 0.68;
        }

        
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin: 15px 0;
        li {
            margin: 0;
        }
        @media screen and(min-width: 1001px) {
            opacity: 1!important;
            animation: none!important;
        }
    }
    
    a.full-text {
        display: none;
    }
    a.wrapped-text {
        display: block;
    }

    @media screen and (max-width: 1000px) {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
        height: 100px;
        align-items: center;
        justify-content: center;

        ul {
            display: flex;
            top: -9999px;
            position: absolute;
            opacity: 0;
            transition: opacity 350ms ease-in;
        }

        ul.active {
            display: flex;
            opacity: 1;
            top: inherit;
            position: fixed;
            background: rgba(255, 255, 255, 0.8);
            left: 0;
            height: 100%;
            width: 100%;
            bottom: 0;
            padding: 0;
            margin: 0;
            font-size: 2em;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            a {
                opacity: 1;
            }

        }
        .logo {
            text-align: center;

            a.full-text {
                display: block
            }
            a.wrapped-text {
                display: none;
            }
        }
    }
}